import { toFieldPath } from "@stackbit/annotations";
import React from "react";
import { Container } from "../components/Container";
import { Section } from "../components/Section";
import { SharedSanitySectionProps } from "../../Layouts/types";
import { TitleArea } from "../components/TitleArea";
import clsx from "clsx";
interface HowItWorksNarrowProps extends SharedSanitySectionProps {
  steps: string[];
}
const COLOR_GRADIENT = ["bg-[#FDF0EE]", "bg-[#FAE0DB]", "bg-[#F5C1B7]", "bg-[#F1A194]", "bg-[#EC8270]", "bg-[#E7634C]"];
const SectionHowItWorksNarrow: React.FC<HowItWorksNarrowProps> = ({
  annotationPrefix,
  bgClassName,
  sectionVariant = "normal",
  section_id,
  title,
  steps = []
}) => {
  const colors = COLOR_GRADIENT.slice(Math.max(0, COLOR_GRADIENT.length - steps.length), COLOR_GRADIENT.length);
  return <Section id={section_id} variant={sectionVariant} bgClassName={bgClassName} {...toFieldPath(annotationPrefix)} data-sentry-element="Section" data-sentry-component="SectionHowItWorksNarrow" data-sentry-source-file="SectionHowItWorksNarrow.tsx">
      <Container data-sentry-element="Container" data-sentry-source-file="SectionHowItWorksNarrow.tsx">
        <TitleArea preheader={title} preheaderPath="title" isEyebrow titleAlignment="left" className="mb-10" data-sentry-element="TitleArea" data-sentry-source-file="SectionHowItWorksNarrow.tsx" />
        <div className="flex flex-col md:flex-row items-stretch">
          {steps.map((step, index) => <div className="flex flex-row md:flex-col relative group pb-10 md:pb-0 group">
              <div className={clsx(colors[index] || colors[colors.length - 1], "relative text-body-standard group-hover:font-semibold z-10 rounded-full self-start w-10 h-10 flex items-center content-center justify-center group-hover:scale-150 transition-all")}>
                {index + 1}
              </div>
              <div className="h-1 absolute top-5 w-full hidden md:block group-last:bg-gradient-to-r bg-plum-8 group-last:bg-transparent group-last:from-plum-8"></div>
              <div className="w-1 h-full bg-plum-8 absolute left-5 md:hidden group-last:hidden"></div>
              <div className="ml-4 md:ml-4 md:mt-10 text-body-standard font-medium md:mr-14 mr-4 flex-1 ">
                {step}
              </div>
            </div>)}
        </div>
      </Container>
    </Section>;
};
export default SectionHowItWorksNarrow;